import React from "react";
import { Link } from "react-router-dom";
import s from "./Footer.module.scss";

const Footer = () => (
  <footer>
    <ul>
      <Link to="/privacy-policy">
        <li>Privacy Policy</li>
      </Link>
    </ul>
    <p className={s.legal}>
      <em>Version 2.4.3</em>
      <br />
      <span className={s.copyright}>
        Copyright ©2023 FoodMarble - All rights reserved
      </span>
    </p>
  </footer>
);

export default Footer;
