import { DateTime } from "luxon";
import { Currency } from "../../../contexts/OrderContext/OrderContext";

export interface InvoiceAddress {
  address: string | undefined;
  city: string | undefined;
  country: string | undefined;
  email: string | undefined;
  name: string | undefined;
  phone: string | undefined;
  state: string | undefined;
  zipCode: string | undefined;
}

interface InvoiceAddressBackend {
  address: string | null | undefined;
  city: string | null | undefined;
  country: string | null | undefined;
  email: string | null | undefined;
  name: string | null | undefined;
  phone: string | null | undefined;
  state: string | null | undefined;
  zipCode: string | null | undefined;
}

export interface CustomerDetails {
  billing: InvoiceAddress;
  shipping: InvoiceAddress;
  vatId: string | undefined;
}

export interface InvoiceItem {
  description: string | undefined;
  quantity: number | undefined;
  unitPriceGross: number | undefined;
  unitPriceNet: number | undefined;
  unitDiscount: number | undefined;
  unitTax: number | undefined;
  taxRate: number | undefined;
}

interface TInvoice {
  id: number;
  createdOnMs: number;
  periodStartedOnMs: number | undefined;
  periodEndedOnMs: number | undefined;
  customer: CustomerDetails;
  supplier: InvoiceAddress;
  items: InvoiceItem[];
  currency: Currency;
}

export default class Invoice implements TInvoice {
  readonly id: number;
  readonly createdOnMs: number;
  readonly periodStartedOnMs: number | undefined;
  readonly periodEndedOnMs: number | undefined;
  readonly customer: CustomerDetails;
  readonly supplier: InvoiceAddress;
  readonly items: InvoiceItem[];
  readonly currency: Currency;

  constructor({ id, createdOnMs, periodStartedOnMs, periodEndedOnMs, customer, supplier, items, currency }: TInvoice) {
    this.id = id;
    this.createdOnMs = createdOnMs;
    this.periodStartedOnMs = periodStartedOnMs ?? undefined;
    this.periodEndedOnMs = periodEndedOnMs ?? undefined;
    this.customer = {
      billing: parseAddress(customer.billing),
      shipping: parseAddress(customer.shipping),
      vatId: customer.vatId ?? undefined,
    };
    this.supplier = parseAddress(supplier);
    this.items = items;
    this.currency = currency;
  }

  filename(): string {
    const date = DateTime.fromMillis(this.createdOnMs, { zone: 'utc' }).toFormat("yyyy-MM-dd");
    return `${date}_${this.id}.pdf`;
  }

  subTotal(): number {
    return this.items.reduce((sum, item) => sum + ((item.unitPriceNet ?? 0) * (item.quantity ?? 0)), 0);
  }

  taxTotal(rate: number): number {
    const items = this.items.filter(item => item.taxRate === rate);
    return items.reduce((sum, item) => sum + ((item.unitTax ?? 0) * (item.quantity ?? 0)), 0);
  }

  total(): number {
    return this.items.reduce((sum, item) => sum += (item.unitPriceNet ?? 0), 0);
  }
}

const parseAddress = (address: InvoiceAddressBackend): InvoiceAddress => ({
  name: address.name ?? undefined,
  address: address.address ?? undefined,
  city: address.city ?? undefined,
  state: address.state ?? undefined,
  zipCode: address.zipCode ?? undefined,
  country: address.country ?? undefined,
  email: address.email ?? undefined,
  phone: address.phone ?? undefined,
})